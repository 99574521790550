<template>
    <div class="status">
        <span
            class="ball"
            :class="ballColor" />
        <span>{{ value }}</span>
    </div>
</template>

<script>

export default {
    name: 'EffectiveStatus',
    props: {
        value: {
            type: String,
            required: false
        },
        item: {
            type: Object,
            required: true
        },
    },
    computed: {
        ballColor() {
            if (this.value == 'ACTIVE') return 'green';
            if (this.value == 'PAUSED') return 'yellow';
            else return 'red';
        }
    },
};
</script>

<style lang="scss" scoped>
.status{
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}
.ball{
    display: inline-block;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    &.red{
        background: red;
    }
    &.green{
        background: green;
        margin-right: 15px;
    }
    &.yellow{
        background: yellow;
    }
}
</style>